import React from "react";

import Container from "react-bootstrap/Container";

import Markdown from "../features/markdown";

function Kontakt() {
  return (
    <div>
      <Container className="h-100" fluid>
        <div className="d-flex flex-column flex-md-row">
          <div className="me-3">
            <img alt="portrait" src="images/portrait1.jpg" width="300px" />
            <br />
            <span style={{ fontSize: "12px" }}>
              Foto: <a href="https://eliane.ditoy.ch/">Eliane Rutishauser</a>
            </span>
          </div>
          <div>
            <Markdown source="pages/kontakt.md" />
          </div>
        </div>
      </Container>
    </div>
  );
}

export default Kontakt;
