import React from "react";

import Container from "react-bootstrap/Container";

import PageBlock from "./PageBlock";

function Arbeiten() {
  return (
    <div>
      <Container className="h-100" fluid>
        <PageBlock source="pages/ka/kunst.md" title={<><div>Kultur-Agentur</div></>} />
        <PageBlock />     
      </Container>
    </div>
  );
}

export default Arbeiten;
