import React from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import Markdown from "../features/markdown";

type PageBlockProps = {
  source?: string;
  title?: React.ReactElement;
};

function PageBlock({ source, title }: PageBlockProps) {
  const content =
    source == null ? (
      <p style={{ height: "20px" }} />
    ) : (
      <Markdown source={source} />
    );
  const titleClass = source ? "pt-2" : "d-none d-md-inline-block pt-2";
  return (
    <Row>
      <Col sm={0} md={{ span: 3 }} className="page-block-title">
        <h4 className={titleClass}>{title}</h4>
      </Col>
      <Col sm="12" md={{ span: 9 }}>
        {content}
      </Col>
    </Row>
  );
}

export default PageBlock;
