import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";


export const markdownApi = createApi({
  reducerPath: "markdownApi",
  baseQuery: fetchBaseQuery({ baseUrl: "/" }),
  tagTypes: ["Page"],
  endpoints: (builder) => ({
    getMarkdown: builder.query<string, string>({
      query: (page) => ({
        url: `${page}`,
        headers: {
          "Content-Type": "text/plain",
        },
        responseHandler: "text"
      }),
      // `result` is the server response
      providesTags: (result, error, page) => [{ type: "Page", page }]
    }),
  })
});

export const {
  useGetMarkdownQuery,
} = markdownApi;
