import React from "react";

import { NavLink as RRNavLink, Route, Routes } from "react-router-dom";
import type { NavLinkProps } from "react-router-dom";

import ArchitextAgency from "./pages/ArchitextAgency";
import KulturAgentur from "./pages/KulturAgentur";
import Kontakt from "./pages/Kontakt";
import Splash from "./pages/Splash";

import { ErrorBoundary } from "./utils/errors";

function NavLink(props: NavLinkProps) {
  return (
    <div className="nav-link">
      <RRNavLink
        {...props}
        className={({ isActive }) =>
          isActive ? "nav-item active" : "nav-item inactive"
        }
      />
    </div>
  );
}

/**
 * The component that links to the nav styles
 */
function Main() {
  return (
    <div className="h-100 w-100">
      <div className="app-grid">
        <div className="app-navbar">
          <div className="mx-5">
            <h3>
              <NavLink to="/">Intro</NavLink>
            </h3>
          </div>
          <div className="mx-5">
            <h3>
              <NavLink to="/kultur">Kultur-Agentur</NavLink>
            </h3>
          </div>    
          <div className="mx-5">
            <h3>
              <NavLink to="/arch">Architext Agency</NavLink>
            </h3>
          </div>      
          <div className="mx-5">
            <h3>
              <NavLink to="/about">About</NavLink>
            </h3>
          </div>
        </div>
        <div className="app-content">
          <main id="content">
            <Routes>
              <Route path="/arch" element={<ArchitextAgency />} />
              <Route path="/kultur" element={<KulturAgentur />} />
              <Route path="/about" element={<Kontakt />} />
              <Route path="/*" element={<Splash />} />
            </Routes>
          </main>
        </div>
        <div className="app-footer d-flex justify-content-between">
          <div>
            <span>&copy; arch-agent 2013 — {new Date().getFullYear()}</span>{" "}
          </div>
        </div>
      </div>
    </div>
  );
}

function App() {
  return (
    <ErrorBoundary>
      <Routes>
        <Route path="/*" element={<Main />} />
      </Routes>
    </ErrorBoundary>
  );
}

export default App;
