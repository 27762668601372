import React from "react";

import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import Markdown from "../features/markdown";

function Splash() {
  return (
    <div>
      <Container className="h-100" fluid>
        <Row className="splash-jumbo">
          <Col md={6}>
            <Markdown source="pages/splash/splash-jumbo-en.md" />
          </Col>
          <Col md={6}>
            <Markdown source="pages/splash/splash-jumbo-de.md" />
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <Markdown source="pages/splash/splash-en.md" />
          </Col>
          <Col md={6}>
            <Markdown source="pages/splash/splash-de.md" />
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Splash;
